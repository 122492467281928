<template>
  <div>
    <b-button @click="$router.push('/campanhas/novo')">Nova Campanha</b-button>
    <b-button @click="$socket.emit('teste', '123')">dash</b-button>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>